import React,{useEffect,useState} from 'react';
import {setResumeTemplateData,bindEdit} from '../../functional_lib/common_lib';
import { public_url } from '../../ApiUrl';
// import './comp_resm1_2_3.css';
export function Comp_Resm_5(props){
    const [resumeData,setResumeData]=useState({});
    const [defaultImg,setDefaultImg]=useState("");
    useEffect(()=>{
        if(typeof props.setProfile.candidate_master!=="undefined"){
            setResumeData(setResumeTemplateData(props.setProfile));
      
          }
      },[props.setProfile])
      
      useEffect(()=>{
        
        if(typeof resumeData.basic!=="undefined"){
            bindEdit();
            if(resumeData.basic.gender==="male"){
                setDefaultImg("/assets/resm_thumb/male_rems.jpg");
              }else{
                setDefaultImg("/assets/resm_thumb/female_rems.jpg");
              }
        }

},[resumeData])

return(<div class="firsttemplate">
    {/* {JSON.stringify(resumeData)}; */}
    {typeof resumeData.basic!=="undefined"?
    <div class="container resume-container">
         {/* {JSON.stringify(props.setProfile)} */}
        <div class="row row-print2">
            <div class="col-5 left-section">
                <div class="text-center">
                    {typeof resumeData.basic.profile_pic!=="undefined"?
                    <img src={public_url+resumeData.basic.profile_pic} alt="Profile Image" class="rounded-circle img-fluid" width="150" />
                    :<img src={defaultImg} alt="Profile Image" class="rounded-circle img-fluid" width="150" />
                    }
                    
                </div>
                <h2 class="text-center">{resumeData.basic.first_name} {resumeData.basic.last_name}</h2>
                <h4 class="text-center editable" e_type="text" e_key="f" e_id="ft">Web Developer</h4>
                
                <hr/>
                <h5>Contact</h5>
                <p><strong>Phone:</strong> {resumeData.contact.mobile}</p>
                <p><strong>Email:</strong> {resumeData.contact.email}</p>
                <p><strong>Address:</strong> {resumeData.contact.address}</p>
                {/* <hr/> */}
                
                <hr/>
                <h5>Skills</h5>
                <ul>
                <li class="editable" e_type="text" e_key="f" e_id="ft">
                {resumeData.skills.map((item,index)=>{
                return <>{index!==0?", "+item:item}</>
                })
               }
               </li>
                   
                </ul>
                <hr></hr>
                <h5>Language Known</h5>
                <p>
             {resumeData.language.map((item,index)=>{
                return <>{index!==0?", "+item:item}</>
             })}
             </p>   
            </div>
            <div class="col-7 right-section">
            <h4>About me</h4>
            {/* <hr/> */}
            <p class="editable" e_type="textarea" e_key="f" e_id="ft" >Highly skilled Web Developer with 7 years of experience in designing, developing, and maintaining responsive websites. Proficient in HTML, CSS, JavaScript, and various web development frameworks such as React and Angular.</p>
            <hr/>
                <h4>Experience</h4>
                <br/>
                {/* <hr/> */}
                {resumeData.experience.map((item,index)=>{
                return(<>
                
                <div>
                    <h5>{item["duration"]}</h5>
                    <h6>{item["company_name"]}</h6>
                    <p><strong>{item["jobrole_name"]}</strong></p>
                    <p class="editable" e_type="textarea" e_key="f" e_id="ft">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pharetra in lorem at laoreet...</p>
                </div>
                <hr/>
                </>)
                })
                }

<h4>Education</h4>
                <br/>
                {/* <hr/> */}
                {resumeData.education.map((item,index)=>{
                return(<>
                
                <div>
                    <h5>{item["degree"]}</h5>
                    <h6>{item["university"]}</h6>
                    <p><strong>{item["duration"]}</strong></p>
                    {/* <p class="editable" e_type="textarea" e_key="f" e_id="ft">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pharetra in lorem at laoreet...</p> */}
                </div>
                <hr/>
                </>)
                })
                }
                <h4>Preference</h4>
                <br/>
                {/* <hr/> */}
                {resumeData.preference.map((item,index)=>{
                    return(<>
                    
 <div>
 <p><strong>{item["work"]}</strong></p>
 <p>Timeing:{item["job_time"]}</p>
 <p>Shift:{item["job_shift"]}</p>
 <p>City:{item["city_name"]}</p>
</div>
<hr/>
</>
)
                })}
               
               
               
            </div>
        </div>
    </div>:''}
</div>
)
}

export default Comp_Resm_5