import './comp_ejobee_pricing.css'

const Comp_Ejobee_Pricing=(props)=>{
    return(
        <div class="pricing-section">
       
        <div id="pricing" class="pricing section">
    
           
            {/* <div class="container section-title" data-aos11="fade-up">
              <h2>Recruiter's Plan</h2>
            </div>
       */}
            <div class="container" data-aos11="fade-up" data-aos-delay11="100">
      
              <div class="row gy-4">
              {props.lsBusinessPlans.map((item,index)=>{
                let arrScope=item["description"].split(',');
                // alert(arrScope.length);
                return(<div class="col-lg-4" data-aos11="zoom-in" data-aos-delay11="200">
                    <div class="pricing-item">
        
                      <div class="pricing-header">
                      {typeof props.onChangeSelectPlan!=="undefined"?<div className="radio-btn">
                            <input type="radio" value={item.id} name='planid' id={"pland"+item.id} onChange={props.onChangeSelectPlan} />
                          </div>:''}
                          <label for={"pland"+item.id}>
                        <h3>{item.title}</h3>
                        <h3><sup></sup>₹ {item.price}/-<span></span></h3>
                        </label>
                      </div>
                     
        
                      <ul>
                        {arrScope.map((descItem,aIndex)=>{
                            return <li><i class="fa-solid fa-check"> </i><span> {descItem}</span></li>
                        })}
                      </ul>
                        {props.buyNow===true?<div class="text-center mt-auto">
                        <a href="#" class="buy-btn">Buy Now</a>
                      </div>:''}
                      
        
                    </div>
                  </div>)
              })}
                
      
                
      
              </div>
      
            </div>
      
        </div>
        </div>
    );
}
export default Comp_Ejobee_Pricing;