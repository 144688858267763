import React,{useEffect,useState} from 'react';
import {setResumeTemplateData,bindEdit} from '../../functional_lib/common_lib';
import { public_url } from '../../ApiUrl';
import './comp_resm1_2_3.css';

export function Comp_Resm_1_New(props){
  const [resumeData,setResumeData]=useState({});

  const [defaultImg,setDefaultImg]=useState("");

  useEffect(()=>{
    //alert(JSON.stringify(props.setProfile));
    if(typeof props.setProfile.candidate_master!=="undefined"){
      setResumeData(setResumeTemplateData(props.setProfile));
    }
    
  },[props.setProfile])

  useEffect(()=>{
    if(typeof resumeData.basic!=="undefined"){
      bindEdit();
      if(resumeData.basic.gender==="male"){
          setDefaultImg("/assets/resm_thumb/male_rems.jpg");
        }else{
          setDefaultImg("/assets/resm_thumb/female_rems.jpg");
        }
  }
  },[resumeData])

  return(<div class="newresumetemplate02">
    
    {typeof resumeData.basic!=="undefined"?
    <div class="resume">
        <div class="header">
            <div class="personal-info mt-3">
                <h1>{resumeData.basic.first_name} {resumeData.basic.last_name}</h1>
                <p><b>Date Of Birth</b>: {resumeData.basic.dob}</p>
                <p><b>Gender</b>: {resumeData.basic.gender}</p>
                <p><b>Email</b>: {resumeData.contact.email}</p>
                <p><b>Mobile Number</b>: {resumeData.contact.mobile}</p>
                <p><b>Address</b>: {resumeData.contact.address}</p>
            </div>
            <div class="photo mt-3">
                <div class="photo-placeholder">
                {typeof resumeData.basic.profile_pic!=="undefined"?
                  <img src={public_url+resumeData.basic.profile_pic} alt="" />:
                  <img src={defaultImg} />
                  }
                  
                  </div>
            </div>
        </div>
        <div class="content">
            <div class="section">
                <h2 class="editable" e_type="text" e_key="f" e_id="ft">Career Objective</h2>
                <p class="editable" e_type="textarea" e_key="f" e_id="ft">"If given the opportunity to work at your esteemed company, it would be a significant milestone in my career. I am confident that I can contribute positively to your team and am eager to take on any challenges presented to me. My approach to work is characterized by a strong sense of responsibility, dedication, and commitment. I believe in giving my best in every task, no matter how big or small, and I am always willing to go the extra mile to ensure that the work is completed to the highest standards. Your organization represents an ideal environment for me to further develop my skills and contribute meaningfully. I am enthusiastic about the prospect of being part of a team that values hard work and innovation, and I am ready to invest my time and effort to make a positive impact."</p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="section">
                    <h2>Education</h2>
                    {resumeData.education.map((item,index)=>{
                      return <>
                      <p><b>{item["degree"]}<sup></sup></b> <br/>{item["university"]}</p>
                      <p>Duration: {item["duration"]}</p>
                      </>
                    })}
                    {/* // <p><b>10<sup>th</sup></b> <br/>Kendriya Vidhyalaya, Ujjain (M.P.)</p>
                    // <p>Duration: Jan-1992 To Dec-1994</p>
                    // <p><b>ITI In Electrical</b> <br/>Government ITI Ujjain (M.P.)</p>
                    // <p>Duration: Jun-1995 To May-1996</p>
                    // <p><b>12<sup>th</sup> In P.C.B. Stream</b> <br/>Central School, Ujjain (M.P.)</p>
                    // <p>Duration: Jul-1996 To Jun-1997</p> */}
                </div>
                <div class="section">
                    <h2>Experience</h2>
                    <p><b>{resumeData.years_exp} Years</b></p>
                    {resumeData.experience.map((item,index)=>{
                      return <>
                      <p>{item["company_name"]} <br/> {item["jobrole_name"]}</p>
                      <p>Duration: {item["duration"]}</p>
                      </>
                    })}
                    {/* <p>Raoun Power Private Limited <br/> Computer Operator</p>
                    <p>Duration: Jan-1997 To Dec-1997</p>
                    <p>Quick Find Infotech <br/> Office Management</p>
                    <p>Duration: Mar-1998 To Feb-2000</p> */}
                </div>
            </div>
            <div class="col">
                <div class="section">
                    <h2>Skills</h2>
                    <p class="editable" e_type="textarea" e_key="f" e_id="ft">
                    {resumeData.skills.map((item,index)=>{
                      
                      
                      return <>{index!==0?", "+item:item}</>
                    })}
                    </p>
                    
                    {/* <p>Problem Solving</p>
                    <p>Project Management</p>
                    <p>Digital Tools</p>
                    <p>Continuous Learning</p> */}
                </div>
                <div class="section">
                    <h2>Job Preference</h2>
                    {resumeData.preference.map((item,index)=>{
                return <>
               <p>Work Type: {item["work"]}</p>
                    <p>Timing: {item["job_time"]}</p>
                    <p>Job Shift: {item["job_shift"]}</p>
                    <p>Pref. City: {item["city_name"]}</p>
                </>
              })}
                </div>
                <div class="section">
                    <h2>Language Known</h2>
                    <p>  {resumeData.language.map((item,index)=>{
            
            return <span class="phone-val">
              {" "+item}{(index<resumeData.language.length-1)?",":''}
            </span>
          })}</p>
                </div>
            </div>
        </div>
            
            
            <div class="section">
                <h2 class="editable" e_type="text" e_key="f" e_id="ft">Declaration</h2>
                <p class="editable" e_type="textarea" e_key="f" e_id="ft">I hereby declare that all the information made above are true and correct to the best of my knowledge and belief.</p>
            </div>
            <div class="footer">
                <p class="editable" e_type="text" e_key="f" e_id="ft">Date: 06/08/2024</p>
                <p class="editable" e_type="text" e_key="f" e_id="ft">SIGNATURE</p>
            </div>
        
    </div>:''}

</div>)
}
export function Comp_Resm_1(props){
  const [resumeData,setResumeData]=useState({});
  useEffect(()=>{
    //alert(JSON.stringify(props.setProfile));
    if(typeof props.setProfile.candidate_master!=="undefined"){
      setResumeData(setResumeTemplateData(props.setProfile));
      // if(typeof resumeData.basic!=="undefined"){
        
      // }
      setTimeout(() => {
        bindEdit();
      }, 1000);
      
    }
    
  },[props.setProfile])
return(

<div class="secondtemplate">
{/* {JSON.stringify(resumeData)}; */}
  {typeof resumeData.basic!=="undefined"?
    <div class="container">
      {/* {JSON.stringify(props.setProfile)} */}
      
      <div class="header">
        <div class="full-name">
          <span class="first-name">{resumeData.basic.first_name}</span> 
          <span class="last-name">{resumeData.basic.last_name}</span>
        </div>
        <div class="contact-info">
          <span class="email">Email: </span>
          <span class="email-val">{resumeData.contact.email}</span>
          <span class="separator"></span>
          <span class="phone">Phone: </span>
          <span class="phone-val">{resumeData.contact.mobile}</span>
          <br/>
          {/* <span class="separator"></span> */}

          <span class="phone">City: </span>
          <span class="phone-val">{resumeData.contact.address}</span>
          <br/>
          {resumeData.language.length>0?<span class="phone">Language known: </span>:''}
          {resumeData.language.map((item,index)=>{
            
            return <span class="phone-val">
              {" "+item}{(index<resumeData.language.length-1)?",":''}
            </span>
          })}
          <br/>
          <span class="phone">DOB: </span>
          <span class="phone-val">{resumeData.basic["dob"]}</span>

        </div>
        
        <div class="about">
          <span class="position editable" e_type="text" e_key="f" e_id="ft">Front-End Developer </span>
          <span class="desc editable" e_type="textarea" e_key="f" e_id="ft">
            I am a front-end developer with more than 3 years of experience writing html, css, and js. I'm motivated, result-focused and seeking a successful team-oriented company with opportunity to grow. 
          </span>
        </div>
      </div>
       <div class="details">
        <div class="section">
          <div class="section__title">Experience {resumeData.years_exp}</div>
          <div class="section__list">
            {resumeData.experience.map((item,index)=>{
              return(<div class="section__list-item">
                <div class="left">
                  <div class="name">{item["company_name"]}</div>
                  <div class="addr">{item["jobrole_name"]}</div>
                  <div class="duration">{item["duration"]}</div>
                </div>
                <div class="right">
                  <div class="name editable" e_type="text" e_key="f" e_id="ft">Front-End Developer</div>
                  <div class="desc editable" e_type="textarea" e_key="f" e_id="ft">Automated repetitive tasks using Gulp, resulting in a more efficient development workflow and faster build times.</div>
                </div>
              </div>)
            })}
          </div>
        </div>
        <div class="section">
          <div class="section__title">Education</div>
          {resumeData.education.map((item,index)=>{
            return(<div class="section__list">
              <div class="section__list-item">
                <div class="left">
                  <div class="name">{item["degree"]}</div>
                  <div class="addr">{item["university"]}</div>
                  <div class="duration">{item["duration"]}</div>
                </div>
                <div class="right">
                  <div class="name editable" e_type="text" e_key="f" e_id="ft">BCA</div>
                  <div class="desc editable" e_type="textarea" e_key="f" e_id="ft">Data Structures, Algorithms, Web Development, Software Engineering,</div>
                </div>
              </div></div>)
          })}
          
           
          
      </div>
         <div class="section">
          <div class="section__title editable" e_type="text" e_key="f" e_id="ft">Projects</div> 
           <div class="section__list">
             <div class="section__list-item">
               <div class="name editable" e_type="text" e_key="f" e_id="ft">Responsive E-Commerce Website</div>
               <div class="text editable" e_type="textarea" e_key="f" e_id="ft">Developed a fully responsive e-commerce website using React.js and Redux. The website includes features such as product listings, a shopping cart, and a checkout process.</div>
             </div>
             
             <div class="section__list-item">
                        <div class="name editable" e_type="text" e_key="f" e_id="ft">Real-Time Chat Application</div>
               <div class="text editable" e_type="textarea" e_key="f" e_id="ft">Created a real-time chat application that supports multiple chat rooms and private messaging. Users can create accounts, join chat rooms, and send messages in real-time.
               </div>
             </div>
           </div>
        </div>
         <div class="section">
           <div class="section__title">Skills</div>
           <div class="skills">
            {resumeData.skills.map((item,index)=>{
              return(<div class="skills__item">
                <div class="left"><div class="name">
                  {item}
                  </div></div>
                <div class="right">
                               <input  id="ck1" type="checkbox" checked/>
     
                  <label for="ck1"></label>
                               <input id="ck2" type="checkbox" checked/>
     
                   <label for="ck2"></label>
                              <input id="ck3" type="checkbox" />
     
                   <label for="ck3"></label>
                                <input id="ck4" type="checkbox" />
                 <label for="ck4"></label>
                               <input id="ck5" type="checkbox" />
                   <label for="ck5"></label>
     
                </div>
              </div>
              
            )
            })}
             
         </div>
             
           </div>
         <div class="section">
         <div class="section__title">
           Job preference11
           </div>
           <div class="section__list">
             <div class="section__list-item">
              {resumeData.preference.map((item,index)=>{
                return <>
                <li><b>Work :</b>{item["work"]}</li>
                <li><b>Timing :</b>{item["job_time"]}</li>
                <li><b>Job Shift:</b> {item["job_shift"]}</li>
                <li><b>Pref. City:</b>{item["city_name"]}</li>
                </>
              })}
              </div>
           </div>
         </div>
         </div>
      </div>:''}
    </div>
    
   
)
}
export default Comp_Resm_1