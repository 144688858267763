import React, { useEffect, useState } from 'react'
import { Link,useParams,useNavigate,useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { blog_post_service } from '../../ApiServices'
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCondidatesView } from '../../ApiServices'
import { toast } from 'react-toastify';
import { public_url } from '../../ApiUrl';
import {CandidateOption} from './CandidateOption';

import {exportPdf} from '../../functional_lib/common_lib';

import {Comp_Template_1} from '../../resume/temp1/comp_template_1';
import {Comp_Template_2} from '../../resume/temp2/comp_template_2';

import {Comp_Resm_1,Comp_Resm_1_New} from '../../resume/resm1/comp_resm_1';
import {Comp_Resm_2,Comp_Resm_2_New} from '../../resume/resm2/comp_resm_2';
import {Comp_Resm_3} from '../../resume/resm3/comp_resm_3';
import {Comp_Resm_4} from '../../resume/resm4/comp_resm_4';
import {Comp_Resm_5} from '../../resume/resm5/comp_resm_5';
import {Comp_Resm_3_New} from '../../resume/resm3_new/comp_resm_3_new';







const Resume_Page = () => {
    const { name } = useParams();
    const location = useLocation();
    //alert(name);
    //alert("i m in resume maker");
    const navigate = useNavigate();

    const [candidateProfile, setCandidateProfile] = useState({});

    useEffect(() => {
        fetchProfileRecords();
    }, [])

    const fetchProfileRecords = () => {
        if (localStorage.getItem('candidate_id')) {

            let map={cand_id:btoa(localStorage.getItem('candidate_id'))};
    
            getCondidatesView(map,"getCandidateProfView").then((res) => {
              //alert(JSON.stringify(res));
              const newCandidates = JSON.parse(res.data);
              
             setCandidateProfile(newCandidates);
             
             
            }).catch((error) => {
              toast('❌' + error.message);
              if (error.message === 'Unauthorized: Please log in.') {
                localStorage.clear();
                navigate('/')
                window.location.reload();
              }
            })

        }
       
        
      }


   


    return (
        <>
            <Helmet>
                <title>Resume Maker - Insights, Tips, and Career Advice | EJOBEE</title>
                <meta name="description" content="Explore the eJobee blog for valuable insights, tips, and career advice. Stay updated with the latest trends in the job market and enhance your career growth." />
            </Helmet>
            <section>
            <div className="container">
               <CandidateOption pathname={location.pathname} />
            </div>
                <div className="container">
                    <div className="section-title mb-5">
                        <h4>Make your Smart Resume with E-Jobee</h4>
                    </div>
                    {typeof candidateProfile.candidate_master!=="undefined"?
                    <div className="btns d-flex gap-2">
                    {/* <div className="btn btn-light">Save</div> */}
                     <div onClick={()=>exportPdf(0,location)} className="btn btn-light">Export Pdf</div> 
</div>    :''                    
                    }
                    
                                        
                        <div id="divPrintContent" className="row" style={{ rowGap: '50px'}}>
                            
                                {/* {name==="1"?<Comp_Template_2/>:<Comp_Template_1/>} */}
                                
                                {/* final */}
                                {name==="1"?<Comp_Resm_1_New setProfile={candidateProfile}/>:''}
                                {name==="2"?<Comp_Resm_2_New setProfile={candidateProfile}/>:''}
                                {name==="3"?<Comp_Resm_3_New setProfile={candidateProfile} />:''}
                                {name==="4"?<Comp_Resm_4 setProfile={candidateProfile}/>:''}
                                {name==="5"?<Comp_Resm_4 setProfile={candidateProfile}/>:''}
                                {name==="6"?<Comp_Resm_5 setProfile={candidateProfile}/>:''}

                                {/* end */}
                               
                               
                               
                               
                            
                        </div>
                    
                </div>
            </section>
        </>
    )
}

export default Resume_Page